import React from 'react';
import './About.scss';

const About = () => {

    return (
        <>
            <div className="about">
                <div className="top-right">
                    <img className="photo-charles" src="charles.jpg" alt="foto charles"/>
                    <b>Charles Greiner</b>
                </div>
                <div >
                    <p>I'm mainly active as a Java backend developer. Currently I'm developing this web page as a means to enhance my frontend skills.</p>
                    <p>This page has been created using React using typescript, css, and html. The goal is to get familiar with frontend layout, component creation, interaction with services which provide security (keycloak) or data (java backend yet to be created).</p>
                    <p>As you can see, it is a new field for me...</p>
                </div>
            </div>
        </>
    );
};

export default About;