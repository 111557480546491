import React from 'react';

function Private() {
    
    return (
        <div className= "private">
            <h1>Private</h1>
            <p>This is private!!!</p>
            If you are not logged in, and you can still see this page I made a mistake...
        </div>
    )
}

export default Private;