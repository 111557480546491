import React from 'react';
import keycloakService from "../../services/authentication/KeycloakService";

const Logout: React.FC = () => {
    const keycloak =  keycloakService.getKeycloakInstance();
    const userName: string = keycloak?.idTokenParsed?.preferred_username;

    const logout = () => {
        if (keycloak) {
            keycloak.logout();
        } else {
            console.error("Tried to logout before keycloak was available!");
        }
    }

    // const profile = getUserProfile();
    const mustLogin = (
        <div>
            Not logged in!
        </div>
    );

    const loggedin = (
        <div>
            Log out {userName}
            <button onClick={logout}>Log out</button>
        </div>
    );

    return keycloak?.authenticated ? loggedin : mustLogin;
};

export default Logout;