import React from 'react';
import "./Home.scss";

function Home() {
    return (
        <div className="home">
            <h1>Home</h1>
            <p>Greiner development page</p>
        </div>
    )
}

export default Home;