import React, {FC} from 'react';

import './App.scss';
import Navbar from "./components/sidebar/Navbar";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Header from "./components/header/Header";
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Private from './pages/private/Private';
import Bookkeeping from "./pages/bookkeeping/Bookkeeping";
import PrivateRoute from "./components/private/PrivateRoute";
import About from "./pages/about/About";
import NotFound from "./pages/notfound/NotFound";
import Footer from "./components/footer/Footer";


const App: FC = () => {

    return (
        <div className="App">
            <BrowserRouter >
                <Header />
                <Navbar />
                <Routes >
                    <Route path='/' element={<Home />}/>
                    <Route path='/login/*' element={<Login/>}/>
                    <Route path='/logout/*' element={<Logout/>}/>

                    <Route path='/private/*' element={<PrivateRoute role=''> <Private/> </PrivateRoute>} />
                    <Route path='/bookkeeping' element={<PrivateRoute role='accountant' > <Bookkeeping/> </PrivateRoute>} />

                    <Route path='/about/*' element={<About/>}/>

                    <Route path={'/404'} element={  <NotFound/> }/>
                    {/*// take care of redirects for all paths that doesn't match the defined*/}
                    <Route path={'*'} element={<Navigate replace to={'/index.html'}/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    )
}

export default App;
