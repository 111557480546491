import React from 'react';
import './Footer.scss';

function Footer() {
    let packageJson = require('../../../package.json')

    return (
        <div className="footer">
            {packageJson.name} {packageJson.version}
        </div>
    )
}

export default Footer;