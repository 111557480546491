import React from 'react';
import keycloakService from "../../services/authentication/KeycloakService";

const Login: React.FC = () => {
    const keycloak =  keycloakService.getKeycloakInstance();
    const userName: string = keycloak?.idTokenParsed?.preferred_username;

    const login = () => {
        if (keycloak) {
            keycloak.login();
        } else {
            console.error("Tried to login before keycloak was available!");
        }
    }

    const logout = () => {
        if (keycloak) {
            keycloak.logout();
        } else {
            console.error("Tried to logout before keycloak was available!");
        }
    }

    /**
     * Returns the user's profile if they are logged in, or `null` if they are not
     */
    async function getUserProfile() {
        if (keycloak?.authenticated) {
            const profile = await keycloak.loadUserProfile();
            return profile;
        }

        return null;
    }

    // const profile = getUserProfile();
    const mustLogin = (
        <div>
            <h1>Login</h1>
            <button onClick={login}>Login with Keycloak</button>
        </div>
    );

    const loggedin = (
        <div>
            <h1>Welcome {userName}</h1>
            <button onClick={logout}>Log out</button>
        </div>
    );

    return keycloak?.authenticated ? loggedin : mustLogin;
};

export default Login;