import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import keycloakService from "../../services/authentication/KeycloakService";
import Unauthorized from "../../pages/unauthorized/Unauthorized";

const PrivateRoute = (props: { children: React.ReactNode, role: string }): JSX.Element => {
    const { children, role } = props
    const keycloak =  keycloakService.getKeycloakInstance();
    const isLoggedIn: boolean = keycloak != null && keycloak.authenticated === true;
    const hasRole: boolean = keycloak != null && (role === '' || (role !== '' && keycloak.hasRealmRole(role)));
    const roleName: string = role === '' ? 'no role' : role;
    console.log(keycloak);
    console.log("keycloak auth? " + keycloak?.authenticated);
    console.log('log ' + roleName + ': ' + hasRole);
    const location = useLocation();

    const hasRolePart = hasRole ? <>{children}</> :  <Unauthorized/>;
    const reDirectToLogin =  (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}`  }}
        />
    );

    return isLoggedIn ? hasRolePart : reDirectToLogin;
}
export default PrivateRoute;