import React from "react";
import {IoIosHand} from "react-icons/io";
import './Unauthorized.scss';

const Unauthorized: React.FC = () => {
    return (
        <div className="unauthorized">
            <IoIosHand />
            <div>You are not authorized to see this page</div>
            <IoIosHand />
        </div>
    );
}

export default Unauthorized;