import React, {FC, useEffect, useState} from 'react';
import Keycloak from "keycloak-js";
import KeycloakService from "../../services/authentication/KeycloakService";
import App from "../../App";

const eventLogger = (event: any, error:any) => {
    console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens:any) => {
    console.log('onKeycloakTokens', tokens);
};

export const KeycloakInit: FC = () => {
    const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const keycloakService = KeycloakService.getInstance();
        initKeycloak(keycloakService);
    }, []);

    function initKeycloak(keycloakService: KeycloakService) {
        keycloakService.initializeKeycloak().then((keycloak) => {
            // You can now use the initialized Keycloak instance
            console.log('Keycloak initialized:', keycloak);
            setKeycloak(keycloak);
            setLoading(false);
        }).catch((reason) => {
            console.log("Exception occurred initializing keycloak")
        });
    }

    // Render loading state while keycloak is initializing
    if (loading) {
        return <div className="kcProvider">Loading...</div>;
    }

    if (!keycloak) {
        // Handle the case where initialization fails
        return <div>Error initializing Keycloak.</div>;
    }

    if (keycloak) {
        console.log("Keycloak has been initialized; loading application");
    }

    return <App />
}
